<style scoped lang="less">
.deviceLabel {
  margin-left: 10px;
  font: bold 14px Georgia;
}

.line {
  background-color: #e5e5e5;
  height: 1px;
  margin-top: 5px;
}

.pop-width {
  width: 200px;
}

.col-label {
  display: inline-block;
}

.pop {
  .ivu-row,
  .ivu-row-flex {
    margin-bottom: 20px;
  }
}
.content {
  padding: 0;
}
</style>

<template>
  <div class="content">
    <div
      v-if="!!vasRedirect && vasRedirect !== 'null'"
      style="padding: 24px  0px 0px 16px"
    >
      <a :href="vasRedirect" target="_blank">点击前往增值服务平台 >></a>
    </div>

    <div id="search_top" class="search-top">
      <div class="input-group-inline right">
        <Button type="primary" icon="md-search" @click="dataQuery">查询</Button>
        <Button icon="trash-a" @click="clear" style="margin-left:5px;"
          >清空</Button
        >
      </div>

      <div class="input-group-inline" style="margin-right: 16px;">
        <label>设备号：</label>
        <Input
          class="control-width"
          type="textarea"
          v-model="queryData.devices"
          placeholder="请输入设备号"
          :autosize="{ maxRows: 2, minRows: 1 }"
        ></Input>
      </div>
      <!--<div class="input-group-inline">-->
      <!--<label>账号：</label>-->
      <!--<Input class="control-width" v-model="queryData.phone" placeholder="请输入账号" :maxlength="15"></Input>-->
      <!--</div>-->
      <!--<div class="input-group-inline">-->
      <!--<label>归属公司：</label>-->
      <!--<Input class="control-width" v-model="queryData.company" placeholder="请输入归属公司"></Input>-->
      <!--</div>-->
      <!--<div class="input-group-inline">-->
      <!--<label>ICCID：</label>-->
      <!--<Input class="control-width" type="textarea" v-model="queryData.iccid" placeholder="请输入ICCID" :autosize="{maxRows: 2,minRows: 1}"></Input>-->
      <!--</div>-->
      <!--<div class="input-group-inline">-->
      <!--<label>库存状态：</label>-->
      <!--<Select class="custom-control-width" v-model="queryData.deliveryStatus">-->
      <!--<Option value="0">全部</Option>-->
      <!--<Option value="1">待出库</Option>-->
      <!--<Option value="2">已出库</Option>-->
      <!--<Option value="3">售后中</Option>-->
      <!--</Select>-->
      <!--</div>-->
      <div class="input-group-inline" style="margin-right: 16px;">
        <label>设备状态：</label>
        <Select
          class="custom-control-width"
          v-model="queryData.status"
          size="default"
        >
          <Option value="">全部</Option>
          <Option value="1">在线</Option>
          <Option value="2">离线</Option>
          <Option value="3">未激活</Option>
        </Select>
      </div>
      <div class="input-group-inline" style="margin-right: 16px;">
        <label>充电状态：</label>
        <Select
          class="custom-control-width"
          v-model="queryData.chargerState"
          size="default"
        >
          <Option value="">全部</Option>
          <Option value="0">未充电</Option>
          <Option value="1">充电中</Option>
        </Select>
      </div>
      <div class="input-group-inline" style="margin-right: 16px;">
        <label>电量≤：</label>
        <Select
          class="custom-control-width"
          style="width:90px;"
          v-model="queryData.powerRate"
          size="default"
        >
          <Option value="100">100%</Option>
          <Option value="90">90%</Option>
          <Option value="80">80%</Option>
          <Option value="70">70%</Option>
          <Option value="60">60%</Option>
          <Option value="50">50%</Option>
          <Option value="40">40%</Option>
          <Option value="30">30%</Option>
          <Option value="20">20%</Option>
          <Option value="10">10%</Option>
          <Option value="5">5%</Option>
        </Select>
      </div>
      <!--<div class="input-group-inline">-->
      <!--<label>所属平台：</label>-->
      <!--<Select class="custom-control-width" v-model="queryData.platform">-->
      <!--<Option value="0">全部</Option>-->
      <!--<Option value="1">阿里IOT</Option>-->
      <!--<Option value="2">微科云图</Option>-->
      <!--</Select>-->
      <!--</div>-->
      <div class="input-group-inline">
        <label style="line-height: 34px">设备型号：</label>
        <Treeselect
          style="float:right;width: 200px;"
          class="custom-control-width"
          v-model="queryData.pids"
          :multiple="true"
          :options="deviceModeList"
          :searchable="false"
          placeholder="请选择"
        >
        </Treeselect>
      </div>
      <!--<div class="input-group-inline">-->
      <!--<label>关联车辆：</label>-->
      <!--<Input class="control-width" v-model="queryData.phone" placeholder="请输入关联车辆" :maxlength="30"></Input>-->
      <!--</div>-->
      <!--<div class="input-group-inline">-->
      <!--<label>物联卡状态：</label>-->
      <!--<Select class="custom-control-width" v-model="queryData.cardStatus">-->
      <!--<Option value="">全部</Option>-->
      <!--<Option value="正常">正常</Option>-->
      <!--<Option value="待激活">待激活</Option>-->
      <!--<Option value="停机">停机</Option>-->
      <!--<Option value="异常">异常</Option>-->
      <!--<Option value="停机保号">停机保号</Option>-->
      <!--</Select>-->
      <!--</div>-->
      <!--<div class="input-group-inline">-->
      <!--<label>物联卡到期：</label>-->
      <!--<DatePicker :value="queryData.cardTime" type="daterange" split-panels placeholder="请选择日期" style="width: 200px" @on-open-change="openCardTime" @on-change="changeCardTime"></DatePicker>-->
      <!--</div>-->
      <!--<div class="input-group-inline" >-->
      <!--<label style="line-height: 34px">到期时间：</label>-->
      <!--<DatePicker type="date" v-model="queryData.expireTimeString" placeholder="请选择" style="width: 200px"></DatePicker>-->
      <!--</div>-->
    </div>

    <Table
      id="table"
      highlight-row
      stripe
      style="overflow: visible;"
      ref="table"
      size="default"
      :loading="loading"
      :height="tableHeight"
      :columns="columns"
      :data="tableData"
    ></Table>
    <div id="page-box" class="page-box">
      <!-- <div class="page-foot">
        <Checkbox size="default" v-model="isAll" @on-change="handleSelectAll">{{
          isAll ? '取消' : '全选'
        }}</Checkbox>
        <Button
          type="primary"
          size="default"
          @click="startBatchReportedRate($refs.table.getSelection())"
          >指令设置</Button
        >
        <Button
          type="primary"
          size="default"
          @click="deviceDistri($refs.table.getSelection())"
          >分配设备</Button
        >
      </div> -->

      <Page
        :total="total"
        :current="current"
        size="small"
        :page-size="pageSize"
        show-total
        @on-change="changePage"
      ></Page>
    </div>

    <!--设备导入结果-->
    <Modal width="400" v-model="showUploadState" title="设备导入">
      <div>
        <transition name="fade">
          <Progress
            hide-info
            :stroke-width="2"
            :status="uploadStatus"
            :percent="uploadPercentage"
          ></Progress>
        </transition>
        <div style="word-break: break-all;">{{ uploadMessage }}</div>
      </div>
    </Modal>

    <!--服务设置弹窗-->
    <Modal
      width="650"
      v-model="showSerSetting"
      :title="showSerFlag == '1' ? '服务设置' : '服务设置(批量设备)'"
      @on-ok="sendSetting"
    >
      <div
        class="sms-title"
        style="padding:0 0 15px 0;"
        v-if="showSerFlag != '1'"
      >
        <div>当前已选择 {{ currentDeviceNum }} 个设备</div>
      </div>
      <div class="sms-title" style="padding:0 0 15px 0;">
        <label style="line-height: 34px">到期时间：</label>
        <DatePicker
          type="date"
          v-model="daloagTime"
          placeholder="请选择"
          style="width: 200px"
        ></DatePicker>
      </div>
    </Modal>

    <!--分配设备弹窗-->
    <Modal
      width="650"
      v-model="showDeviceDistri"
      :title="showSerFlag == '1' ? '分配设备' : '分配设备(批量设备)'"
      @on-ok="setDeviceDistri"
    >
      <div
        class="sms-title"
        style="padding:0 0 15px 0;"
        v-if="showDeviceFlag != '1'"
      >
        <div>当前已选择 {{ currentDeviceNum }} 个设备</div>
      </div>
      <div class="sms-title" style="padding:0 0 15px 0;">
        <label style="line-height: 34px">分配组织：</label>
        <Select v-model="deviceDistriCompanyId" class="control-width">
          <Option v-for="item in companyJson" :value="item.id" :key="item.id">{{
            item.company
          }}</Option>
        </Select>
      </div>
    </Modal>

    <!--短信发送-->
    <Modal
      width="650"
      v-model="showSmsModal"
      title="短信发送"
      @on-ok="sendSmsDo"
    >
      <div class="sms-title" style="padding:0 0 15px 0;">
        <div v-if="isSingleSendSms">
          设备号：{{ currentDevice }}
          <span style="margin-left:20px;">ICCID:{{ currentICCID }}</span>
        </div>
        <div v-else>当前已选择 {{ currentDeviceNum }} 个设备</div>
      </div>
      <div class="sms-content">
        <Input
          v-model="smsContent"
          :maxlength="200"
          type="textarea"
          placeholder="请输入短信内容"
          style="width:100%"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import { ajax, formateDate, getUrlParam } from "../../libs/util";
import tableHelper from "../../components/mixins/table-helper.vue";
import deviceMix from "../../components/mixins/device-mix";
import moment from "moment";
import Cookies from "js-cookie";

export default {
  components: { Treeselect },
  mixins: [tableHelper, deviceMix],
  data() {
    //console.log(this.$route);
    let routeQuery = this.$route.query || {};
    let queryData = {
      chargerState: "",
      powerRate: "",
      devices: "",
      phone: "",
      company: "",
      iccid: "",
      deliveryStatus: "0",
      cardStatus: "",
      status: "",
      platform: "",
      expireTime: "",
      expireTimeString: "",
      pids: [],
      // cardTime:[this.getBeforeYearMonth(0)+'-01',this.getBeforeYearMonth(-1)+'-01'],
      cardTime: [],
      // startValidDate:this.getBeforeYearMonth(0)+'-01 00:00:00',
      // endValidDate:this.getBeforeYearMonth(-1)+'-01 23:59:59',
      startValidDate: "",
      endValidDate: "",
      ...routeQuery,
    };

    return {
      tokenFromUrl: getUrlParam("token"),
      vasRedirect: decodeURIComponent(getUrlParam("vas_redirect")),
      deviceDistriCompanyId: "",
      companyJson: [],
      queryData: queryData,
      isAll: false,
      current: 1,
      pageSize: 50,
      tableHeight: "",
      total: 0,
      tableData: [],
      deviceModeList: [],
      loading: false,
      columns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          type: "index",
          title: "序号",
          width: 70,
          align: "center",
        },
        {
          title: "操作",
          key: "action",
          width: 70,
          align: "center",
          render: this.actionRender,
        },
        // {
        //     title: '平台',
        //     width: 130,
        //     key: 'platformName'
        // },

        {
          title: "设备号",
          minWidth: 150,
          key: "device",
        },
        {
          title: "型号",
          minWidth: 80,
          key: "productModel",
        },
        {
          title: "设备状态",
          minWidth: 80,
          key: "statusName",
        },
        {
          title: "电量",
          minWidth: 80,
          align: "center",
          render: (h, params) => {
            if (params.row.powerRate) {
              return h("span", `${params.row.powerRate}%`);
            } else {
              return "";
            }
          },
        },
        {
          title: "充电状态",
          minWidth: 130,
          key: "chargerStateName",
        },
        {
          title: "最后一次定位时间",
          minWidth: 150,
          key: "locateTimeStr",
        },
        {
          title: "定位类型",
          minWidth: 90,
          key: "locateTypeName",
        },
        // {
        //   title: '定位模式',
        //   minWidth: 130,
        //   key: 'modeName',
        // },
        // {
        //   title: '定位周期',
        //   minWidth: 130,
        //   key: 'reportedRate',
        //   render: (h, params) => {
        //     const { mode, reportedRate } = params.row
        //     let dw = ''
        //     switch (mode) {
        //       case 0:
        //         dw = '分钟/次'
        //         break
        //       case 1:
        //         dw = '秒/次'
        //         break
        //       default:
        //         break
        //     }

        //     return h('span', reportedRate ? reportedRate + dw : '')
        //   },
        // },
        {
          title: "出库时间",
          minWidth: 150,
          key: "deliveryTimeStr",
        },
        {
          title: "到期时间",
          minWidth: 150,
          key: "expireTimeStr",
        },
        // {
        //   title: '所属组织',
        //   minWidth: 200,
        //   key: 'companyName',
        // },
      ],
      addLoading: false,
      uploadData: { service: "/device/import_through_excel" },
      showUploadState: false,
      showSerSetting: false,
      showSerFlag: "",
      showDeviceFlag: "",
      daloagTime: "",
      daloagDevice: "",
      uploadPercentage: 0,
      uploadMessage: "",
      uploadStatus: "active",
      showSmsModal: false,
      chartCurrentData: {},
      //查看日志
      showLogState: false,
      showTemperatureState: false,
      showDeviceDistri: false,
      logLoading: true,
      tabActiveIndex: 0,
      tabActiveTem: 0,

      logDevice: "",
      temperatureDevice: "",
      configLogColumns: [
        {
          type: "index",
          title: "序号",
          width: 60,
          align: "center",
        },
        {
          key: "content",
          title: "操作类型",
          align: "center",
        },
        {
          key: "operatorName",
          title: "操作人",
          align: "center",
        },
        {
          key: "created",
          title: "操作时间",
          align: "center",
          render: (h, params) => {
            return h("span", formateDate(params.row.created * 1000));
          },
        },
      ],
      directiveLogColumns: [
        {
          type: "index",
          title: "序号",
          width: 60,
          align: "center",
        },
        {
          key: "logOperator",
          title: "操作人",
          width: 120,
          align: "center",
        },
        {
          key: "cmdContent",
          title: "指令内容",
          width: 150,
          align: "center",
        },
        {
          key: "cmdTypeStr",
          title: "指令类型",
          width: 120,
          align: "center",
        },
        {
          key: "created",
          title: "下发时间",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("span", formateDate(params.row.created * 1000));
          },
        },
        {
          key: "resultMsg",
          title: "下发状态",
          width: 180,
          align: "center",
          render: (h, params) => {
            if (!params.row.resultMsg) {
              params.row.resultMsg =
                params.row.resultCode === 1 ? "成功" : "失败";
            }
            return h("span", {}, params.row.resultMsg);
          },
        },
        {
          key: "executeTime",
          title: "执行时间",
          width: 150,
          align: "center",
          render: (h, params) => {
            return h("span", formateDate(params.row.executeTime * 1000));
          },
        },
        {
          key: "executeState",
          title: "执行状态",
          width: 120,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.executeState
                ? params.row.executeState == 1
                  ? "成功"
                  : "失败"
                : ""
            );
          },
        },
      ],
      logTableHeight: 400,
      logTableData: [],
      currentDevice: "",
      currentICCID: "",
      smsContent: "",
      isSingleSendSms: true,
      currentDeviceNum: 0,
      logTotal: 0,
      logCurrent: 1,
      logPageSize: 10,
    };
  },
  methods: {
    //短信发送
    sendSms(row) {
      ajax.post(
        "/xhk_web",
        {
          service: "device/fetch_one",
          device: row.device,
        },
        (res) => {
          if (res.status === 0) {
            if (res.body.sim) {
              this.isSingleSendSms = true;
              this.currentDevice = res.body.device;
              this.currentICCID = res.body.iccid;
              this.showSmsModal = true;
            } else {
              this.$Message.error("此设备没有ICCID");
            }
          }
        },
        (err) => {
          this.$Message.error(err.message || err.msg || "服务器异常，请重试");
        }
      );
    },
    startBatchSendSms(selectionList) {
      if (selectionList && selectionList.length > 0) {
        this.isSingleSendSms = false;
        this.currentDeviceNum = selectionList.length;
        let devices = selectionList.map((item) => item.device);
        this.currentDevice = devices.join(",");
        this.showSmsModal = true;
      } else {
        this.$Message.error("请选择要操作的设备");
      }
    },
    startBatchSerStting(selectionList) {
      if (selectionList && selectionList.length > 0) {
        this.isSingleSendSms = false;
        this.currentDeviceNum = selectionList.length;
        let devices = selectionList.map((item) => item.device);
        this.currentDevice = devices.join(",");
        this.showSerSetting = true;
      } else {
        this.$Message.error("请选择要操作的设备");
      }
    },
    sendSmsDo() {
      ajax.post(
        "/xhk_web",
        {
          service: "device/command/send_sms",
          devices: this.currentDevice,
          commandParams: [
            { cmd: "sendSMS", params: { content: this.smsContent } },
          ],
        },
        (res) => {
          if (res.status === 200) {
            if (res.message === "SUCCESS") {
              this.$Message.success("短信发送成功");
            } else {
              this.$Message.error(res.message || "短信发送失败");
            }
          }
        },
        (err) => {
          this.$Message.error(err.message || "服务器异常，请重试");
        }
      );
    },
    openCardTime(isOpen) {
      if (isOpen) {
        let endTime =
          new Date(this.getBeforeYearMonth(-1) + "-01").getTime() -
          24 * 60 * 60 * 1000;
        let endTimeVal = formateDate(endTime).substr(0, 10);
        this.queryData.cardTime = [
          this.getBeforeYearMonth(0) + "-01",
          endTimeVal,
        ];
        this.queryData.startValidDate =
          this.getBeforeYearMonth(0) + "-01 00:00:00";
        this.queryData.endValidDate = endTimeVal + " 23:59:59";
      }
    },
    changeCardTime(time) {
      this.queryData.startValidDate = time[0] ? time[0] + " 00:00:00" : "";
      this.queryData.endValidDate = time[1] ? time[1] + " 23:59:59" : "";
    },
    getBeforeYearMonth(i) {
      let cur = new Date().getMonth() + 1;
      let curYear = new Date().getFullYear();
      let returnM = cur - i;
      if (returnM < 1) {
        returnM = returnM + 12;
        curYear = curYear - 1;
      } else if (returnM > 12) {
        returnM = returnM - 12;
        curYear = curYear + 1;
      }
      returnM = returnM < 10 ? "0" + returnM : returnM;
      return curYear + "-" + returnM;
    },
    dataQuery() {
      this.query();
    },
    query(page = 1) {
      this.loading = true;

      let reqData = Object.assign({ service: "device/list" }, this.queryData);
      reqData.devices = this.queryData.devices
        .replace(/\ +/g, ",")
        .replace(/[\r\n]/g, ",");
      reqData.iccid = this.queryData.iccid
        .replace(/\ +/g, ",")
        .replace(/[\r\n]/g, ",");
      reqData.deliveryStatus =
        this.queryData.deliveryStatus === "0"
          ? ""
          : this.queryData.deliveryStatus;
      reqData.cardStatus = this.queryData.cardStatus;
      reqData.status =
        this.queryData.status === "0" ? "" : this.queryData.status;
      reqData.platform =
        this.queryData.platform === "0" ? "" : this.queryData.platform;
      reqData.pids = this.queryData.pids.filter(Boolean).join(",");
      reqData.expireTime = this.queryData.expireTimeString
        ? moment(this.queryData.expireTimeString).unix()
        : "";
      reqData.page = page;
      reqData.pageSize = this.pageSize;
      ajax.post(
        "/device/list",
        reqData,
        (res) => {
          //客户维度搜索
          if (this.queryData.uid) {
            delete this.queryData.uid;
          }
          //订单维度搜索
          if (this.queryData.oid) {
            delete this.queryData.oid;
          }
          if (res.code === 0) {
            this.tableData = res.data.data;
            this.total = res.data.total;
            this.isAll = false;
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.$Message.error(err.message || "服务器异常，请重试");
        }
      );
    },
    getDeviceModeList() {
      ajax.post(
        "/device/get_product_model_list",
        {},
        (res) => {
          this.loading = false;
          if (res.code === 0) {
            //为了使用TreeSelect 的数据格式
            this.deviceModeList = [
              {
                id: "",
                label: "全部",
                isDefaultExpanded: true,
                children: _.sortBy(res.data, ["name"]).map((item) => ({
                  id: item.id,
                  label: item.productModel,
                })),
              },
            ];
          } else {
            this.deviceModeList = [];
          }
        },
        (err) => {
          this.deviceModeList = [];
          this.$Message.error(err.message || "服务器异常，请重试");
        }
      );
    },
    getCompanyList() {
      ajax.post(
        "/customer/list",
        {
          page: 1,
          pageSize: 999,
        },
        (res) => {
          this.loading = false;
          if (res.code === 0) {
            //为了使用TreeSelect 的数据格式
            this.companyJson = res.data.data;
          } else {
            this.companyJson = [];
          }
        },
        (err) => {
          this.companyJson = [];
          this.$Message.error(err.message || "服务器异常，请重试");
        }
      );
    },
    clear() {
      this.queryData = {
        devices: "",
        phone: "",
        company: "",
        iccid: "",
        deliveryStatus: "0",
        cardStatus: "",
        status: "0",
        platform: "0",
        pids: [],
        // cardTime:[this.getBeforeYearMonth(0)+'-01',this.getBeforeYearMonth(-1)+'-01'],
        cardTime: [],
        // startValidDate:this.getBeforeYearMonth(0)+'-01 00:00:00',
        // endValidDate:this.getBeforeYearMonth(-1)+'-01 23:59:59',
        startValidDate: "",
        endValidDate: "",
      };
    },
    changePage(page) {
      this.query(page);
    },
    handleSelectAll() {
      this.$refs.table.selectAll(this.isAll);
    },
    resetFields(value) {
      // 重置数据
      this.showDeviceDtlModal = false;
      this.showRateModal = false;
      this.addLoading = false;
      this.$refs[value].resetFields();
    },
    goTrailPage(row) {
      let path = this.tokenFromUrl ? "/trail/back" : "/devices/trail_back";
      this.$router.push({
        path: path,
        query: { device: row.device },
      });
    },
    actionRender(h, params) {
      let dtlBtn = h(
        "Button",
        {
          props: { type: "text" },
          on: {
            click: () => {
              this.showDeviceDetail(params.row);
            },
          },
        },
        "设备详情"
      );
      let smsBtn = h(
        "Button",
        {
          props: { type: "text" },
          on: {
            click: () => {
              this.sendSms(params.row);
            },
          },
        },
        "短信发送"
      );
      let rrBtn = h(
        "Button",
        {
          props: { type: "text" },
          on: {
            click: () => {
              this.startReportedRate(params.row);
            },
          },
        },
        "指令设置"
      );
      let deviceBtn = h(
        "Button",
        {
          props: { type: "text" },
          on: {
            click: () => {
              this.deviceDistri(params.row, "1");
            },
          },
        },
        "分配设备"
      );
      let serviceDateBtn = h(
        "Button",
        {
          props: { type: "text" },
          on: {
            click: () => {
              this.serviceDate(params.row, "1");
            },
          },
        },
        "服务设置"
      );
      let trailBtn = h(
        "Button",
        {
          props: { type: "text" },
          on: {
            click: () => {
              this.goTrailPage(params.row);
            },
          },
        },
        "轨迹回放"
      );

      //   return this.renderGear(h, rrBtn, deviceBtn, trailBtn)
      return this.renderGear(h, trailBtn);
    },
    beforeUploadHandle() {
      this.uploadPercentage = 0;
      this.uploadStatus = "active";
      this.uploadMessage = "";
      this.showUploadState = true;
      return true;
    },
    uploadSuccessHandle(response) {
      this.uploadPercentage = 100;
      this.uploadMessage =
        response.status === 200 ? response.body : response.message;
      this.uploadStatus = response.status === 200 ? "success" : "wrong";
      if (response.status === 200) {
        this.query();
      }
    },
    //分配设备
    deviceDistri(row, type) {
      console.log(row);
      this.showDeviceFlag = type;
      this.deviceDistriCompanyId = "";
      if (type === "1") {
        this.showDeviceDistri = true;
        this.daloagDevice = row.device;
      } else {
        if (row && row.length > 0) {
          this.currentDeviceNum = row.length;
          let devices = row.map((item) => item.device);
          this.daloagDevice = devices.join(",");
          this.showDeviceDistri = true;
        } else {
          this.$Message.error("请选择要操作的设备");
        }
      }
    },
    // 服务设置
    serviceDate(row, type) {
      this.daloagTime = "";
      this.showSerFlag = type;
      if (type == "1") {
        this.showSerSetting = true;
        if (row.expireTime) {
          this.daloagTime = formateDate(row.expireTime * 1000);
        }
        this.daloagDevice = row.device;
      } else {
        if (row && row.length > 0) {
          this.isSingleSendSms = false;
          this.currentDeviceNum = row.length;
          let devices = row.map((item) => item.device);
          this.daloagDevice = devices.join(",");
          this.daloagTime = this.currentDateLater();
          console.log(this.daloagTime);
          this.showSerSetting = true;
        } else {
          this.$Message.error("请选择要操作的设备");
        }
      }
    },
    setDeviceDistri() {
      if (!this.deviceDistriCompanyId) {
        this.$Message.error("请选择要分配的组织");
        return;
      }
      ajax.post(
        "/device/allocate",
        {
          cid: this.deviceDistriCompanyId,
          devices: this.daloagDevice,
        },
        (res) => {
          if (res.code === 0) {
            this.$Message.success("分配设备成功");
            this.query();
          }
        },
        (err) => {
          this.$Message.error(err.message || "服务器异常，请重试");
        }
      );
    },
    currentDateLater() {
      var date = new Date();
      var y = date.getFullYear() + 1;
      var m = date.getMonth() + 1;
      // m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      // d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      // h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "1" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d;
    },
    sendSetting() {
      ajax.post(
        "/xhk_web",
        {
          service: "device/modify_expire_time",
          devices: this.daloagDevice,
          expireTime: moment(this.daloagTime).unix(),
        },
        (res) => {
          if (res.status === 0) {
            this.query();
          }
        },
        (err) => {
          this.$Message.error(err.message || "服务器异常，请重试");
        }
      );
    },
    landLogin() {
      ajax.post(
        "/user/token_login",
        {
          token: decodeURIComponent(this.tokenFromUrl),
        },
        (res) => {
          Cookies.set("token", res.data.token, { path: "/" });
          Cookies.set("name", res.data.userinfo.name, { path: "/" });
          this.initData();
        },
        (err) => {
          this.$Message.error(err.msg || "服务器异常，请重试");
        }
      );
    },
    initData() {
      this.getDeviceModeList();
      this.getCompanyList();
      this.query();
    },
  },
  created() {
    if (this.tokenFromUrl) {
      this.landLogin();
    } else {
      this.initData();
    }
  },
  mounted() {},
  beforeRouteUpdate() {
    this.clear();
    this.query();
  },
};
</script>
